import { queryForGetFarm, queryForInsertFarm } from "../queries";
import { executeApi } from "../common";

export default () => ({
  async getFarms(context) {
    const response = await executeApi(queryForGetFarm);
    context.commit('setFarmsInState', response?.data?.black_Address);
    return response?.data?.black_Address;
  },
  async insertFarm(context, metadata) {
    // const newQuery = queryForInsertUser.replace('data: []', `data: ${metadata.data}`).replace('name: ""', `name: "${metadata.name}"`).replace('phone: ""', `phone: "${metadata.phone}"`).replace('email: ""', `email: "${metadata.email}"`);
    const response = await executeApi(queryForInsertFarm, metadata);
    // return response?.data?.insert_black_User?.returning?.length;
    return response?.data?.insert_black_Address?.returning?.length;
  },
});
  