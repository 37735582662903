export async function executeApi(query, variables) {
	const body = variables && Object.keys(variables)?.length ? JSON.stringify({ query, variables }) : JSON.stringify({ query });
	const response = await fetch("https://apis.butterpaper.tech/v1/graphql", {
		method:'POST',
		headers: {
			'x-hasura-company-id': 'e0cbecb4-17f4-40e3-9a20-ee9d10057be3',
			'x-hasura-project-id': '61edb93a-d4b6-45a9-8acf-9c90aaa4c89f',
		},
		body,
	});
	return await response.json();
}