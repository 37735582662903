import { queryForGetGrowersData, queryForLogin, queryForGetGRNReport, queryForAddGRNReport, queryForAddDispatchReportGinner, queryForGetGroups, queryForGetUsers, queryForGetCrop, queryForGetCropLogs, queryForCropReports, queryForAddCropLog, queryForAddCropReport, queryForGetSeeds, queryForGetDispatchReports, queryForAddDispatchReport} from "../queries";
import { executeApi } from "../common";

export default () => ({
  async login(context, variables) {
    const newQuery = queryForLogin.replace('#email', variables?.email).replace('#password', variables?.password)
    const response = await executeApi(newQuery);
    if(response?.data?.black_User) localStorage.setItem('loginDetails', JSON.stringify(response?.data?.black_User));
    return response?.data?.black_User?.length;
  },
  setLoginDetails(context, variables) {
    context.commit('setLoginDetailsInState', variables);
  },
  async getCrops(context) {
    const response = await executeApi(queryForGetCrop);
    context.commit('setCropsInState', response?.data?.black_Product);
    return response?.data?.black_Product;
  },
  async getSeeds(context) {
    const response = await executeApi(queryForGetSeeds);
    context.commit('setSeedsInState', response?.data?.black_seeds);
    return response?.data?.black_seeds;
  },
  async getGroups(context) {
    const response = await executeApi(queryForGetGroups);
    context.commit('setGroupsInState', response?.data?.black_Group);
    return response?.data?.black_Group;
  },
  async getDispatchFromList(context, groupName) {
    const modQ = queryForGetUsers.replace('Farmer', groupName);
    const response = await executeApi(modQ);
    context.commit('setDispatchFromListInState', response?.data?.black_User);
    return response?.data?.black_User;
  },
  async getDispatchToList(context, groupName) {
    const modQ = queryForGetUsers.replace('Farmer', groupName);
    const response = await executeApi(modQ);
    context.commit('setDispatchToListInState', response?.data?.black_User);
    return response?.data?.black_User;
  },
  async getDispatchReports(context) {
    const newQuery = queryForGetDispatchReports.replace('black_dispatched', `black_dispatched(where: {dispatchFrom: {_eq: "${context?.state?.loginDetails[0]?.id}"}})`)
    const response = await executeApi(newQuery);
    context.commit('setDispatchReportsInState', response?.data?.black_dispatched);
    return response?.data?.black_dispatched;
  },
  async getGRNReports(context) {
    const newQuery = queryForGetGRNReport.replace('black_grmReport', `black_grmReport(where: {dispatched: {dispatchTo: {_eq: "${context?.state?.loginDetails[0]?.id}"}}})`)
    const response = await executeApi(newQuery);
    context.commit('setGRNReportsInState', response?.data?.black_grmReport);
    return response?.data?.black_grmReport;
  },
  async getCropReportsByPasscode(context, logId) {
    const forReports = queryForGetDispatchReports.replace('black_dispatched', `black_dispatched(where: {passCode: {_eq: "${logId}" }})`)
    const response = await executeApi(forReports);
    context.commit('setCgetCropReportsByPasscodeInState', response?.data?.black_dispatched);
    return response?.data?.black_dispatched?.length;
  },
  async getCropReports(context, logId) {
    const forReports = queryForCropReports.replace('black_cropReports', `black_cropReports(where: {cropUserMapping: { id: {_eq: "${logId}"}}})`)
    const response = await executeApi(forReports);
    context.commit('setCropReportsInState', response?.data?.black_cropReports);
    return response?.data?.black_cropReports;
  },
  async getCropLogs(context, logId) {
    let response;
    if (logId) {
      const forLog = queryForGetCropLogs.replace('black_cropUserMapping', `black_cropUserMapping(where: {id: {_eq: "${logId}"}})`)
      response = await executeApi(forLog);
      context.commit('setCropLogInState', response?.data?.black_cropUserMapping);
      return response?.data?.black_cropUserMapping;
    } else {
      response = await executeApi(queryForGetCropLogs);
      context.commit('setCropLogsInState', response?.data?.black_cropUserMapping);
      return response?.data?.black_cropUserMapping;
    }
  },
  async insertCropLog(context, variables) {
    const response = await executeApi(queryForAddCropLog, variables);
    return response?.data?.insert_black_cropUserMapping_one?.id;
  },
  async insertGRNReport(context, variables) {
    const response = await executeApi(queryForAddGRNReport, variables);
    return response?.data?.insert_black_grmReport_one?.id;
  },
  async insertCropReport(context, variables) {
    const response = await executeApi(queryForAddCropReport, variables);
    return response?.data?.insert_black_cropReports_one?.name;
  },
  async insertDispatchReport(context, variables) {
    const response = await executeApi(queryForAddDispatchReport, variables);
    return response?.data?.insert_black_dispatched_one?.id;
  },
  async insertDispatchReportGinner(context, variables) {
    const response = await executeApi(queryForAddDispatchReportGinner, variables);
    return response?.data?.insert_black_dispatched_one?.id;
  },
  async clearDispatchReportsByPasscode(context) {
    context.commit('clearDispatchReportsByPasscodeInState', []);
  },
  async getGrowersData() {
    const response = await executeApi(queryForGetGrowersData);
    if (response)
      return response.data.black_dispatched;
  },
});
  