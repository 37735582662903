import actionModule from './actions';

const initializeState = () => ({
  users: [],
  meta: [],
});

export default () => ({
  state: initializeState(),
  mutations: {
    setUsersInState(state, payload) {
      state.users = payload.length ? payload : [];
    },
    setMetaInState(state, payload) {
      state.meta = payload.length ? payload : [];
    },
  },
  actions: actionModule(),
  getters: {
    users: (state) => state.users,
    meta: (state) => state.meta,
  },
});
