// 
export const queryForLogin = `{
  black_User(where: {_and: [{email:{_eq:"#email"}},{password:{_eq:"#password"}}]}){
    id
    email
    name
    User_Meta(where: {Project_MetaKey: {name: {_eq: "image" }}}) {
      value
    }
    User_Groups {
        groupId
    }
    Addresses {
        id
        label
        State {
            name
        }
        City {
            name
        }
    }
    User_Companies {
        id
        User {
            name
            id
        }
    }
  }
}`;
export const queryForGetUsers = `{
    black_User(where: {User_Groups:{Group:{name:{_eq: "Farmer"}}}}){
        id
        name
        phone
        email
        created_at
        updated_at
        Addresses {
          id 
          label
          farmingLand
        }
        User_Meta {
        Project_MetaKey{
            id
            name
        }
        value
        active
        }
    }
}`;

export const queryForGetMeta = `{
    black_Project_MetaKey{
        id
        name
        master
    }
}`;

export const queryForGetSeeds = `{
  black_seeds{
    id
    name
    brand
    type
  }
}`;

export const queryForInsertUser = `mutation {
    insert_black_User (objects: {
      name: "",
      phone: "",
      email: "",
      password: "1234",
      companyId: "e0cbecb4-17f4-40e3-9a20-ee9d10057be3",
      User_Projects:{
        data: {
          projectId:"61edb93a-d4b6-45a9-8acf-9c90aaa4c89f"
        }
      }
      User_Meta: {
        data: []
      }
      User_Companies:{
        data: {
          companyId:"e0cbecb4-17f4-40e3-9a20-ee9d10057be3"
          active:true
        }
      }
      User_Groups: {
        data: {
          groupId: "7570f700-c20c-43c3-a623-df80830fc74b"
          projectId:"61edb93a-d4b6-45a9-8acf-9c90aaa4c89f",
          companyId: "e0cbecb4-17f4-40e3-9a20-ee9d10057be3"
        }
      }
    }) {
      returning {
        id
        name
      }
    }
  }`;

export const queryForGetFarm = `{
    black_Address{
      id
      label
      waterSource
      soilType
      irrigationMethod
      image
      farmingLand
      State {
        id
        name
      }
      Country {
        id
        name
      }
      City {
        id
        name
      }
      User {
        id
        name
      }
      addressType
      addressLine1
      addressLine2
      soilReportLink
      createdAt
    }
  }`

export const queryForInsertFarm = `mutation insert_black_Address($label: String, $waterSource: String, $soilType: String, $irrigationMethod: String, $image: String, $stateId: uuid, $farmingLand: numeric, $countryId: uuid, $userId: uuid, $cityId: uuid, $addressType: String, $addressLine1: String, $zipcode: String, $isprimary: Boolean, $isverified: Boolean, $addressLine2: String, $soilReportLink: String) {
  insert_black_Address (objects: {
    userId: $userId,
    farmingLand: $farmingLand,
    isverified: $isverified
    isprimary: $isprimary,
    zipcode: $zipcode,
    label: $label,
    waterSource: $waterSource,
    soilType:$soilType,
    irrigationMethod: $irrigationMethod,
    image: $image,
    stateId: $stateId,
    countryId:$countryId,
    cityId:$cityId,
    addressType:$addressType,
    addressLine1:$addressLine1,
    addressLine2:$addressLine2,
    soilReportLink:$soilReportLink
  }){
    returning{
      label
      id
    }
  }
}`

export const queryForAddCropLog = `mutation insert_black_cropUserMapping_one(
  $userId:uuid,
  $addressId:uuid,
  $productId:uuid,
  $sowingYear: numeric,
  $season: String,
  $isActive: Boolean
) {
  insert_black_cropUserMapping_one(object:{
    userId: $userId
    addressId: $addressId
    productId: $productId
    sowingYear: $sowingYear
    season: $season
    isActive: $isActive
  }){
    id
    sowingYear
    Product{
      name
    }
  }
}`

export const queryForGetCrop = `{
  black_Product(where: {type: {_eq: "crop"}}){
    id
    name
  }
}`

export const queryForGetGroups = `{
    black_Group{
        id
        name
    }
}`

export const queryForGetCropLogs = `{
    black_cropUserMapping {
      id
      User{
        id
        name
        User_Meta(where: {Project_MetaKey: {name: {_eq: "image" }}}) {
          value
        }
      }
      Product{
        id
        name
      }
      cropReports(where: {name: {_eq:"sowing_report"}}){
        name
        seed{
          name
          brand
          type
        }
      }
      Address{
        id
        label
        State{
          name
        }
        City{
          name
        }
      }
      sowingYear
      season
    }
}`

export const queryForAddCropReport= `
  mutation insert_black_cropReports_one($name: String, $startDate: timestamp, $endDate: timestamp, $meta: jsonb, $seedId:uuid, $reportId: uuid, $volume: Int, $unitType: String){
    insert_black_cropReports_one(object: {
      name:$name,
      startDate: $startDate,
      endDate: $endDate,
      meta: $meta,
      seedId: $seedId,
      volume: $volume,
      unitType:$unitType,
      reportId: $reportId
    }){
      name
      startDate
      endDate
      meta
      seedId
      reportId
      volume
      unitType
  }
}`

export const queryForCropReports= `{
  black_cropReports {
    id
    seed {
      name
    }
    startDate
    endDate
    name
    meta
    volume
    unitType
    cropUserMapping {
      id
    }
    createdAt
    updatedAt
  }
}`

export const queryForGetDispatchReports= `{
  black_dispatched {
    id
    arrivalTime
    passCode
    vehicleNo
    volume
    unitType
    cropUserMapping{
      sowingYear
      id
      Product{
        name
      }
      User {
          id
          name
          User_Meta(where: {Project_MetaKey: {name: {_eq: "image" }}}) {
            value
          }
      }
      Address {
          id
          label
          State{
              id
              name
          }
          City{
              id
              name
          }
      }
    }
  }
}`

export const queryForAddDispatchReportGinner= `
mutation insert_black_dispatched_one($corpLogId: uuid, $volume: Int, $data: [black_dispatchRelation_insert_input!]!
  $unitType: String, $vehicleNo: String, $dispatchFrom: uuid,
  $dispatchTo: uuid, $dispatchTime: timestamp,
  $arrivalTime: timestamp, $passCode: String) {
  insert_black_dispatched_one(object: {corpLogId: $corpLogId, volume: $volume, unitType: $unitType, vehicleNo: $vehicleNo, dispatchTo: $dispatchTo, dispatchTime: $dispatchTime, arrivalTime:
    $arrivalTime, passCode: $passCode, dispatchFrom: $dispatchFrom,
    dispatchRelations: {data: $data }}) {
    id
    volume
  }
}`


export const queryForAddDispatchReport= `
  mutation insert_black_dispatched_one($corpLogId:uuid, $dispatchFrom: uuid, $volume:Int,
    $unitType: String, $vehicleNo: String, $dispatchTo: uuid, $dispatchTime
  : timestamp, $arrivalTime: timestamp, $passCode: String){
    insert_black_dispatched_one(object: {
      corpLogId: $corpLogId,
      volume: $volume,
      unitType:$unitType,
      vehicleNo: $vehicleNo,
      dispatchFrom: $dispatchFrom,
      dispatchTo: $dispatchTo,
      dispatchTime: $dispatchTime,
      arrivalTime: $arrivalTime,
      passCode: $passCode
    }){
      id
      volume
    }
}`

export const queryForAddGRNReport= `
  mutation insert_black_grmReport_one($unitType: String, $volume: Int, $dispatchId: uuid, $recievedAt: timestamp){
  insert_black_grmReport_one(object: {
    unitType: $unitType,
    volume: $volume,
    dispatchId: $dispatchId,
    recievedAt: $recievedAt
  }){
    id
  }
}`

export const queryForGetGRNReport= `{
  black_grmReport {
    id
    volume
    unitType
    recievedAt
    dispatched {
      unitType
      vehicleNo
      cropUserMapping {
        season
        sowingYear
        Product {
          name
        }
        User {
          name
          User_Meta(where: {Project_MetaKey: {name: {_eq: "image" }}}) {
            value
          }
        }
        season
        Address {
          label
          latitude
          State {
            name
          }
          City {
            name
          }
          Country {
            name
          }
        }
      }
    }
  }
}`

export const queryForGetGrowersData = `{
  black_dispatched(where: {dispatchTo: {_eq: "0b7ce029-2dac-4001-939e-d3736dff49a4"}}) {
    arrivalTime
    id
    volume
    User {
      name
    }
    dispatchRelations{
      id
      volume
      grmReport{
        dispatched{
          volume
          cropUserMapping{
            User{
              name
              User_Groups{
                Group{
                  name
                }
              }
              User_Meta{
                Project_MetaKey{
                  name
                  }
                  value
                }
              }
            }
          }
        }
      }
    }
  }`;