import { queryForGetUsers, queryForInsertUser } from "../queries";
import { executeApi } from "../common";

export default () => ({
  async getUsers(context) {
    const response = await executeApi(queryForGetUsers);
    context.commit('setUsersInState', response.data.black_User);
    return context;
  },
  async insertUser(context, metadata) {
    const newQuery = queryForInsertUser.replace('data: []', `data: ${metadata.data}`).replace('name: ""', `name: "${metadata.name}"`).replace('phone: ""', `phone: "${metadata.phone}"`).replace('email: ""', `email: "${metadata.email}"`);
    const response = await executeApi(newQuery);
    return response?.data?.insert_black_User?.returning?.length;
  },
});
  