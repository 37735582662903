<template>
  <div id="app">
    <router-view id="main-views" />
  </div>
</template>

<script>
import '@/assets/css/tailwind.css';
import '@/assets/js/main.js';

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Poppins,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
  line-height: 1.5 !important;
}
</style>
