import Vue from "vue";
import Vuex from "vuex";

import farmersModule from '@/store/farmers/store';
import farmsModule from '@/store/farms/store';
import cropsModule from '@/store/cropLogs/store';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    modules: {
        farmers: farmersModule(),
        farms: farmsModule(),
        crops: cropsModule(),
    }
})