// Requiring module
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/login',
		name: 'Login',
		component:()=> import( /* webpackChunkName: "LoginPage" */ '@/views/LoginPage.vue')
	},
	{
		path: '/timeline/',
		name: 'Timeline',
		component: () => import( /* webpackChunkName: "Timeline" */ '@/views/Timeline.vue'),
	},
	{
		path: '/',
		name: 'Dashboard',
		component:()=> import( /* webpackChunkName: "Dashboard" */ '@/views/Dashboard.vue'),
		children: [
			{
				path: '/',
				name: 'Home',
				component:()=> import( /* webpackChunkName: "Home" */ '@/components/Home.vue')
			},
			{
				path: '/notifications',
				name: 'Notifications',
				component:()=> import( /* webpackChunkName: "Notifications" */ '@/components/Notifications.vue')
			},
			{
				path: '/farmers',
				name: 'Farmers',
				component:()=> import( /* webpackChunkName: "Farmers" */ '@/components/Farmers.vue')
			},
			{
				path: '/farms',
				name: 'Farms',
				component:()=> import( /* webpackChunkName: "Farms" */ '@/components/Farms.vue')
			},
			{
				path: '/add-farmer',
				name: 'AddFarmer',
				component:()=> import( /* webpackChunkName: "AddFarmer" */ '@/components/AddFarmer.vue')
			},
			{
				path: '/add-farm',
				name: 'AddFarm',
				component:()=> import( /* webpackChunkName: "AddFarm" */ '@/components/AddFarm.vue')
			},
			{
				path: '/crop-logs',
				name: 'CropReports',
				component:()=> import( /* webpackChunkName: "CropReports" */ '@/components/CropLogs.vue')
			},
			{
				path: '/add-crop-log',
				name: 'AddCropLog',
				component:()=> import( /* webpackChunkName: "CropLog" */ '@/components/AddCropLog.vue')
			},
			{
				path: '/edit-crop-log/:logId',
				name: 'EditCropLog',
				component:()=> import( /* webpackChunkName: "CropLog" */ '@/components/EditCropLog.vue')
			},
			{
				path: '/dispatch-reports-vle',
				name: 'DispatchReportsVLE',
				component:()=> import( /* webpackChunkName: "DispatchReports" */ '@/components/DispatchReportsVLE.vue')
			},
			{
				path: '/dispatch-reports-ginner',
				name: 'DispatchReportsGinner',
				component:()=> import( /* webpackChunkName: "DispatchReports" */ '@/components/DispatchReportsGinner.vue')
			},
			{
				path: '/dispatch-reports-spinner',
				name: 'DispatchReportsSpinner',
				component:()=> import( /* webpackChunkName: "DispatchReports" */ '@/components/DispatchReportsSpinner.vue')
			},
			{
				path: '/dispatch-reports-fab-maker',
				name: 'DispatchReportsFabMaker',
				component:()=> import( /* webpackChunkName: "DispatchReports" */ '@/components/DispatchReportsFabMaker.vue')
			},
			{
				path: '/dispatch-reports-mill',
				name: 'DispatchReportsMill',
				component:()=> import( /* webpackChunkName: "DispatchReports" */ '@/components/DispatchReportsEPP.vue')
			},
			{
				path: '/add-dispatch-report-vle',
				name: 'AddDispatchReportVLE',
				component:()=> import( /* webpackChunkName: "AddDispatchReport" */ '@/components/AddDispatchReportVLE.vue')
			},
			{
				path: '/add-dispatch-report-ginner',
				name: 'AddDispatchReportGinner',
				component:()=> import( /* webpackChunkName: "AddDispatchReport" */ '@/components/AddDispatchReportGinner.vue')
			},
			{
				path: '/add-dispatch-report-spinner',
				name: 'AddDispatchReportSpinner',
				component:()=> import( /* webpackChunkName: "AddDispatchReport" */ '@/components/AddDispatchReportSpinner.vue')
			},
			{
				path: '/add-dispatch-report-fab-maker',
				name: 'AddDispatchReportFabMaker',
				component:()=> import( /* webpackChunkName: "AddDispatchReport" */ '@/components/AddDispatchReportFabMaker.vue')
			},
			{
				path: '/add-dispatch-report-mill',
				name: 'AddDispatchReportMill',
				component:()=> import( /* webpackChunkName: "AddDispatchReport" */ '@/components/AddDispatchReportEPP.vue')
			},
			{
				path: '/grn-reports-ginner',
				name: 'GRNReportsGinner',
				component:()=> import( /* webpackChunkName: "GRNReports" */ '@/components/GRNReportsGinner.vue')
			},
			{
				path: '/grn-reports-spinner',
				name: 'GRNReportsSpinner',
				component:()=> import( /* webpackChunkName: "GRNReports" */ '@/components/GRNReportsSpinner.vue')
			},
			{
				path: '/grn-reports-fab-maker',
				name: 'GRNReportsFabMaker',
				component:()=> import( /* webpackChunkName: "GRNReports" */ '@/components/GRNReportsFabMaker.vue')
			},
			{
				path: '/grn-reports-mill',
				name: 'GRNReportsMill',
				component:()=> import( /* webpackChunkName: "GRNReports" */ '@/components/GRNReportsEPP.vue')
			},
			{
				path: '/add-grn-report-ginner',
				name: 'AddGRNReportGinner',
				component:()=> import( /* webpackChunkName: "AddGRNReport" */ '@/components/AddGRNReportGinner.vue')
			},
			{
				path: '/add-grn-report-spinner',
				name: 'AddGRNReportSpinner',
				component:()=> import( /* webpackChunkName: "AddGRNReport" */ '@/components/AddGRNReportSpinner.vue')
			},
			{
				path: '/add-grn-report-fab-maker',
				name: 'AddGRNReportFabMaker',
				component:()=> import( /* webpackChunkName: "AddGRNReport" */ '@/components/AddGRNReportFabMaker.vue')
			},
			{
				path: '/add-grn-report-mill',
				name: 'AddGRNReportMill',
				component:()=> import( /* webpackChunkName: "AddGRNReport" */ '@/components/AddGRNReportEPP.vue')
			},
			{
				path: '/add-distributor',
				name: 'AddDistributer',
				component:()=> import( /* webpackChunkName: "AddGRNReport" */ '@/components/AddDistributer.vue')
			},
			{
				path: '/distributors-list',
				name: 'Distributors',
				component:()=> import( /* webpackChunkName: "AddGRNReport" */ '@/components/Distributers.vue')
			},
			{
				path: '/add-product',
				name: 'AddProduct',
				component:()=> import( /* webpackChunkName: "AddGRNReport" */ '@/components/AddProduct.vue')
			},
			{
				path: '/products-list',
				name: 'Products',
				component:()=> import( /* webpackChunkName: "AddGRNReport" */ '@/components/Products.vue')
			},
			{
				path: '/seeds',
				name: 'Seeds',
				component:()=> import( /* webpackChunkName: "Seeds" */ '@/components/Seeds.vue')
			},
			{
				path: '/add-seed',
				name: 'AddSeeds',
				component:()=> import( /* webpackChunkName: "AddSeeds" */ '@/components/AddSeeds.vue')
			},
		]
	},
	{
		path: '*',
		name: '404Page',
		component:()=> import( /* webpackChunkName: "404Page" */ '@/views/404Page.vue')
	},
]

// Create Vue Router Object
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
