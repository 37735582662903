import actionModule from './actions';

const initializeState = () => ({
  farms: [],
});

export default () => ({
  state: initializeState(),
  mutations: {
    setFarmsInState(state, payload) {
      state.farms = payload?.length ? payload : [];
    },
  },
  actions: actionModule(),
  getters: {
    farms: (state) => state.farms,
  },
});
