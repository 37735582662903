import actionModule from './actions';

const initializeState = () => ({
  crops: [],
  seeds: [],
  groups: [],
  cropLog: [],
  cropLogs: [],
  GRNReports: [],
  logReports: [],
  loginDetails: [],
  dispatchToList: [],
  dispatchReports: [],
  dispatchFromList: [],
  dispatchReportsByPasscode: [],
});

export default () => ({
  state: initializeState(),
  mutations: {
    setLoginDetailsInState(state, payload) {
      state.loginDetails = payload?.length ? payload : [];
    },
    setCropsInState(state, payload) {
      state.crops = payload?.length ? payload : [];
    },
    setCropReportsInState(state, payload) {
      state.logReports = payload?.length ? payload : [];
    },
    setGroupsInState(state, payload) {
      state.groups = payload?.length ? payload : [];
    },
    setDispatchToListInState(state, payload) {
      state.dispatchToList = payload?.length ? payload : [];
    },
    setDispatchFromListInState(state, payload) {
      state.dispatchFromList = payload?.length ? payload : [];
    },
    setDispatchReportsInState(state, payload) {
      state.dispatchReports = payload?.length ? payload : [];
    },
    setCgetCropReportsByPasscodeInState(state, payload) {
      state.dispatchReportsByPasscode = payload?.length ? payload : [];
    },
    setGRNReportsInState(state, payload) {
      state.GRNReports = payload?.length ? payload : [];
    },
    setSeedsInState(state, payload) {
      state.seeds = payload?.length ? payload : [];
    },
    setCropLogInState(state, payload) {
      state.cropLog = payload?.length ? payload : [];
    },
    setCropLogsInState(state, payload) {
      state.cropLogs = payload?.length ? payload : [];
    },
    clearDispatchReportsByPasscodeInState(state) {
      state.dispatchReportsByPasscode = [];
    },
  },
  actions: actionModule(),
  getters: {
    crops: (state) => state.crops,
    seeds: (state) => state.seeds,
    groups: (state) => state.groups,
    cropLog: (state) => state.cropLog,
    cropLogs: (state) => state.cropLogs,
    logReports: (state) => state.logReports,
    GRNReports: (state) => state.GRNReports,
    loginDetails: (state) => state.loginDetails,
    dispatchToList: (state) => state.dispatchToList,
    dispatchFromList: (state) => state.dispatchFromList,
    dispatchReports: (state) => state.dispatchReports,
    dispatchReportsByPasscode: (state) => state.dispatchReportsByPasscode,
  },
});
